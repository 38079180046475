export function checkPassword(rule, value, callback) {
    const reg = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,18}')
    if (!value) {
        return callback(new Error('请输入密码'))
    } else if (value.length < 6) {
        return callback(new Error('密码不能小于6位'))
    } else {
        callback()
    }
// else if (!reg.test(value)) {
//         return callback(new Error('密码中必须包含字母、数字，至少8个字符，最多18个字符'))
//     }
}

export function checkPhone(rule, value, callback) {
    const reg = new RegExp('^[1][3-8][0-9]{9}$')
    if (!value) {
        return callback(new Error('请输入手机号码'))
    } else if (!reg.test(value)) {
        return callback(new Error('请输入有效的手机号码'))
    } else {
        callback()
    }
}

export function checkIdentityNumber(rule, value, callback) {
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
    if (!value) {
        return callback(new Error('请输入身份证号'))
    } else if (!reg.test(value)) {
        return callback(new Error('请输入有效的身份证号'))
    } else {
        callback()
    }
}

export function timeAgo(dateTimeStamp) {
    let result = ''
    const minute = 1000 * 60
    const hour = minute * 60
    const day = hour * 24
    const week = day * 7
    const halfamonth = day * 15;
    const month = day * 30;
    const currentTime = new Date().getTime()
    const timestamp = new Date(dateTimeStamp.replace(/-/g, '/')).getTime()

    const diffValue = currentTime - timestamp
    if (diffValue < 0) {
        return result
    }
    const minC = diffValue / minute;  //计算时间差的分，时，天，周，月
    const hourC = diffValue / hour;
    const dayC = diffValue / day;
    const weekC = diffValue / week;
    const monthC = diffValue / month;
    if (monthC >= 1 && monthC <= 3) {
        result = ' ' + parseInt(monthC) + '月前'
    } else if (weekC >= 1 && weekC <= 4) {
        result = ' ' + parseInt(weekC) + '周前'
    } else if (dayC >= 1 && dayC <= 7) {
        result = ' ' + parseInt(dayC) + '天前'
    } else if (hourC >= 1 && hourC <= 24) {
        result = ' ' + parseInt(hourC) + '小时前'
    } else if (minC >= 1 && minC <= 60) {
        result = ' ' + parseInt(minC) + '分钟前'
    } else if (diffValue >= 0 && diffValue <= minute) {
        result = "刚刚"
    } else {
        let datetime = new Date(dateTimeStamp)
        // datetime.setTime(diffValue)
        let Nyear = datetime.getFullYear();
        let Nmonth = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
        let Ndate = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
        let Nhour = datetime.getHours() < 10 ? "0" + datetime.getHours() : datetime.getHours();
        let Nminute = datetime.getMinutes() < 10 ? "0" + datetime.getMinutes() : datetime.getMinutes();
        let Nsecond = datetime.getSeconds() < 10 ? "0" + datetime.getSeconds() : datetime.getSeconds();
        result = Nyear + "-" + Nmonth + "-" + Ndate
    }
    return result

}

// 富文本替换img标签
export function replaceText(html) {
    if (html) {
        const richText = html.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, '[图片]')
        return richText
    }

}

export function conversionTime(time) {
    const hour = Math.floor(time / 60 / 60) < 10 ? '0' + Math.floor(time / 60 / 60) : Math.floor(time / 60 / 60)
    const minute = Math.floor((time - hour * 60 * 60) / 60) < 10 ? '0' + Math.floor((time - hour * 60 * 60) / 60) : Math.floor((time - hour * 60 * 60) / 60)
    const second = (time - hour * 60 * 60 - minute * 60) < 10 ? '0' + (time - hour * 60 * 60 - minute * 60) : (time - hour * 60 * 60 - minute * 60)
    return hour + ':' + minute + ':' + second
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */
export function handleTree(data, id, parentId, children, rootId) {
    id = id || 'id'
    parentId = parentId || 'parentId'
    children = children || 'children'
    rootId = rootId || Math.min.apply(Math, data.map(item => {
        return item[parentId]
    })) || 0
    //对源数据深度克隆
    const cloneData = JSON.parse(JSON.stringify(data))
    //循环所有项
    const treeData = cloneData.filter(father => {
        let branchArr = cloneData.filter(child => {
            //返回每一项的子级数组
            return father[id] === child[parentId]
        });
        branchArr.length > 0 ? father.children = branchArr : '';
        //返回第一层
        return father[parentId] === rootId;
    });
    return treeData != '' ? treeData : data;
}

export function resetPage(pageNum,pageSize, total, num) {
    const page = Math.ceil((total - num) / pageSize)
    const currentPage = page > pageNum ? pageNum : page
    return currentPage
}
/**
 * 字节单位转换
 * */
export function byteConvert(bytes) {
    if (isNaN(bytes)) {
        return '';
    }
    const symbols = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let exp = Math.floor(Math.log(bytes) / Math.log(2))
    if (exp < 1) exp = 0
    let i = Math.floor(exp / 10)
    bytes = bytes / Math.pow(2, 10 * i)
    if (bytes.toString().length > bytes.toFixed(2).toString().length) bytes = bytes.toFixed(2)
    return bytes + ' ' + symbols[i]
}

// io流下载
export function ioDownload(data,filename){
    const content = data
    const blob = new Blob([content])
    if ('download' in document.createElement('a')) {
        const elink = document.createElement('a')
        elink.download = filename
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href)
        document.body.removeChild(elink)
    } else {
        navigator.msSaveBlob(blob, filename)
    }
}
// 时间转换
export function transTime(value){
    const date = new Date(value)
    const year = date.getFullYear()
    let month = date.getMonth() + 1
    month = month > 9 ? month : '0' + month
    let day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return year + '年' + month + '月' + day + '日' + hours + ':' + minutes + ':' + seconds
}
export function download(link, name) {
    if (!name) {
        name = link.slice(link.lastIndexOf('/') + 1)
    }

    let eleLink = document.createElement('a')
    eleLink.download = name
    eleLink.style.display = 'none'
    eleLink.href = link
    document.body.appendChild(eleLink)
    eleLink.click()
    document.body.removeChild(eleLink)
}
